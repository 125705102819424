.text-yellow{
    color: #FF7C0A !important;
}


.grey-text-100{
    color: #8396AC;
}

.grey-text-200{
    color: #5E6282;
}