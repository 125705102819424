.offered-services {
  flex: 1;
  display: flex;
  width: 100vw;
  opacity: 1;

  @media only screen and (min-width: '1024px') {
    background: url('../../public//assets//Services/back.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 80vh;
  }
  .offer-services-box {
    border: 1px solid #bfc9d5;
    border-radius: 10px;
    cursor: context-menu;
    position: relative;
    margin: 3rem 0.4rem;
    .service-image {
      position: absolute;
      top: -25%;

      // @media only screen and (max-width: '600px') {
      //   top: -27%;
      // }
      // @media only screen and (min-width: '600px') {
      //   top: -28%;
      // }
      // @media only screen and (min-width: '768px') {
      //   top: -36%;
      // }
      // @media only screen and (min-width: '896px') {
      //   top: -18%;
      // }
      // @media screen and (min-width: '992px') {
      //   top: -20%;
      // }
      // @media screen and (min-width: '1200px') {
      //   top: -24%;
      // }
      // @media screen and (min-width: '1760px') {
      //   top: -30%;
      // }
    }
  }
  .offer-services-box-hover {
    border: 1px solid #bfc9d5;
    border-radius: 10px;
    cursor: context-menu;
    position: relative;
    margin: 3rem 0.4rem;
    box-shadow: 3px 2px 20px 1px rgba(66, 73, 80, 0.09);
    .service-image {
      position: absolute;

      top: -25%;
    }
  }
  /* styles.css or any appropriate stylesheet */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(1.05);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .image-animate {
    animation: fadeIn 0.3s ease-in-out forwards;
  }

  .image-none {
    opacity: 0;
  }

  @media only screen and (min-width: 1024px) {
    .d-none {
      display: block;
    }
  }

  @media only screen and (max-width: 1024px) {
    .d-none {
      display: none;
    }
  }
}
