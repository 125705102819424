h1 {
  font-size: 2.125rem !important;
  font-weight: 600 !important;
  color: #232323 !important;
  @media screen and (max-width: 1025px) {
    font-size: 1.6rem !important;
  }
  @media screen and (max-width: 1400px) {
    font-size: 1.7rem !important;
  }
  @media screen and (max-width: 1050px) {
    font-size: 1.4rem !important;
  }
}

h2 {
  font-size: 2.063rem !important;
  font-weight: 600 !important;
  color: #232323 !important;
}

h3 {
  font-size: 1.35rem !important;
  font-weight: 600 !important;
  color: #393939 !important;
  @media screen and (max-width: 1025px) {
    font-size: 1.2rem !important;
  }
}

span {
  color: #232323 !important;
}

/* sizes */

.font-size-10 {
  color: #393939 !important;
  font-size: 10px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  color: #393939 !important;
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
  font-family: 'CustomFont' !important;
}
.font-size-16 {
  // color: #393939 !important;
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-24 {
  font-size: 24px !important;
  color: #232323;
}

.font-size-30 {
  font-size: 30px !important;
}
.font-size-45 {
  font-size: 45px !important;
  color: #232323 !important;
  @media screen and (max-width: '600px') {
    font-size: 30px !important;
  }
}

.font-larger {
  font-size: 4rem;
  font-weight: 600;
  @media screen and (max-width: '600px') {
    font-size: 2.8rem !important;
  }
}
.font-size-60 {
  font-size: 60px !important;
}

/* font weight */

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-1000 {
  font-weight: 100 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}
