.nard-homes-biz {
  max-width: 100%;
  overflow: hidden;
  .nard-list-item {
    border: 1px solid #f0f3f5;
    border-radius: 10px;
    cursor: context-menu;
  }
  .nard-list-item-hover {
    border: 1px solid #f0f3f5;
    border-radius: 10px;
    cursor: context-menu;
    box-shadow: 3px 2px 20px 1px rgba(66, 73, 80, 0.09);
  }
/* styles.css or any appropriate stylesheet */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1.05);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.image-animate {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.image-none {
  opacity: 0;
}

}
