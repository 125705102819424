.about {
  max-width: 100%;
  overflow: hidden;
  .container {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    .subscribe {
      width: 80%;
      background-color: #f3f5f7;
      border-radius: 200px 0 0 0;
      position: relative;
      padding-bottom: 5rem;
      @media screen and (max-width: '600px') {
        width: 92%;
      }
      .send {
        position: absolute;
        right: 0%;
        max-width: 60px;
        top: -4%;
      }
      .mask-right {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.1;
      }
      .mask-left {
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0.1;
      }
    }
    .heading {
      display: flex;
      justify-content: center;
      padding: 5rem 0;
      h3 {
        width: 55%;
        text-align: center;
        color: #5e6282;
      }
    }
    .field {
      display: flex;
      justify-content: center;
    }
  }
  .main-container {
    position: relative;
    .container-review {
      border: 1px solid white;

      @media screen and (max-width: 1400px) {
        width: 400px;
        font-size: 15px;
      }
      @media screen and (min-width: 1400px) {
        width: 500px;
        font-size: 17px;
      }
      @media screen and (max-width: 767px) {
        width: 300px;
        font-size: 13px;
      }

      position: relative;
      background-color: white;
      border-radius: 10px;
      box-shadow: 3px 2px 20px 1px rgba(66, 73, 80, 0.09);
      padding: 1rem 2rem;
      z-index: 1;
    }
    .container-review-light {
      cursor: pointer;

      border: 1px solid #dfe4ea;
      transition: all 0.5s ease-in-out;
      @media screen and (max-width: 1400px) {
        width: 400px;
        font-size: 15px;
      }
      @media screen and (min-width: 1400px) {
        width: 500px;
        font-size: 17px;
      }
      @media screen and (max-width: 767px) {
        width: 300px;
        font-size: 13px;
        left: 10%;
      }
      position: absolute;
      top: 30%;
      left: 20%;
      background-color: white;
      border-radius: 10px;
      padding: 1rem 2rem;
    }
  }
  .decore {
    @media screen and (max-width: 1400px) {
      width: 400px;
    }
    @media screen and (max-width: 767px) {
      width: 250px;
    }
  }

  @keyframes moveForward {
    0% {
      transform: scale(1) translateY(0);
    }
    100% {
      transform: scale(1.05) translateY(-10px);
    }
  }

  @keyframes moveBackward {
    0% {
      transform: scale(1.05) translateY(-10px);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }

  .animate-forward {
    animation: moveForward 0.3s ease-in-out forwards;
  }

  .animate-backward {
    animation: moveBackward 0.3s ease-in-out forwards;
  }

  .container-review {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .animate-forward {
    animation: moveUp 0.5s forwards;
  }

  .animate-backward {
    animation: moveDown 0.5s forwards;
  }

  @keyframes moveUp {
    from {
      transform: translateY(10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes moveDown {
    from {
      transform: translateY(-10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
