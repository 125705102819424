@font-face {
  font-family: 'CustomFont';
  src: url('./font/Outfit-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  margin: 0;
  padding: 0;
}

body,html{
  font-family: 'CustomFont', sans-serif !important;
  scroll-behavior: smooth;
}
