.hero-section{
    max-width: 100%;
    overflow: hidden;
    background: url('../../public/assets/Hero/rainbow.png');
    background-size: 100% 100%;
    height: 690px;
    
    position: relative;
    .empowering-image{
        position: absolute;
        @media screen and (max-width: 1400px) {
            width: 400px;
           
        }
        @media screen and (max-width: 767px) {
            width: 300px;
         
        }
        bottom: 2%;
        left: 20%;
        z-index: 0;
    }
}